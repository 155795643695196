















































































































































































import Vue from 'vue';
import dayjs from 'dayjs';
import mytako, { MonitorHealthStatus } from '@/services/mytako';
import bus from '@/services/bus';
import * as log from '@/services/log';
import segment from '@/services/segment';
import Hotjar from 'vue-hotjar';
import VueAnalytics from 'vue-analytics';
import store from '@/services/store';
import i18n, { locales, localeNames } from '@/services/i18n';

export default Vue.extend({
    metaInfo: {
        // refreshOnceOnNavigation: true,
        titleTemplate: 'MyTako | %s',
        meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' },
            { vmid: 'description', name: 'description', content: 'foo' },
        ],
    },

    data() {
        return {
            MonitorHealthStatus,
            year: dayjs().format('YYYY'),
            language: store.language.get(),
            languages: locales.map((locale) => {
                const text = localeNames[locale.toLowerCase()] ?? locale;
                return { value: locale, text };
            }),
            serverStatus: { status: MonitorHealthStatus.OK, message: '' },
            serverStatusCheckCount: 0,
            reconnectAttemptCount: 0,
        };
    },

    watch: {
        language(newValue: string) {
            store.language.set(newValue);
            i18n.changeLanguage(newValue);
        },
    },

    mounted() {
        document.addEventListener('focus', this.onEnterTab);
        document.addEventListener('blur', this.onLeaveTab);

        this.checkStatusServer();
        setInterval(this.checkStatusServer, process.env.VUE_APP_BACKEND_STATUS_CHECK_TIMEOUT_MSEC);
        Vue.use(Hotjar, {
            id: process.env.VUE_APP_HOTJAR_SITE_ID,
            snippetVersion: 6,
            isProduction: true,
        });
        Vue.use(VueAnalytics, {
            id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
            router: this.$router,
        });

        // this.$ga.event('category', 'action', 'label', 123);
    },

    methods: {
        identifySegmentEvent(name: string, data: unknown) {
            segment.track(name, data);
        },

        trackingSegmentEvent(name: string) {
            segment.track(name);
        },

        onLeaveTab() {
            log.debug('[App] onLeaveTab');
            this.serverStatusCheckCount = process.env.VUE_APP_BACKEND_STATUS_CHECK_COUNT;
        },

        onEnterTab() {
            log.debug('[App] onEnterTab');
            this.serverStatusCheckCount = 0;
            this.checkStatusServer();
        },

        refreshPageBookings() {
            bus.$emit('force-refresh-bookings-page');
        },

        async checkStatusServer() {
            this.serverStatusCheckCount++;
            if (this.serverStatusCheckCount > process.env.VUE_APP_BACKEND_STATUS_CHECK_COUNT) {
                return;
            }
            log.debug('[App] serverStatusCheckCount: ', this.serverStatusCheckCount);
            try {
                const statusServerResponse = await mytako.api.monitors.status();
                // for test:
                // throw new Error('bad');
                this.serverStatus = statusServerResponse.server;
                this.reconnectAttemptCount = 0;
                // for test:
                // this.serverStatus.status = MonitorHealthStatus.DEGRADED;
                // this.serverStatus.status = MonitorHealthStatus.MAINTENANCE;
            } catch {
                // could not connect to server ... try a few times then switch to maintenance mode
                if (this.reconnectAttemptCount < process.env.VUE_APP_BACKEND_RECONNECTION_ATTEMPT_COUNT) {
                    this.reconnectAttemptCount++;
                    setTimeout(this.checkStatusServer, process.env.VUE_APP_BACKEND_RECONNECTION_ATTEMPT_TIMEOUT_MSEC);
                } else {
                    this.serverStatus = { status: MonitorHealthStatus.MAINTENANCE, message: '🚧' };
                }
            }
        },
    },
});
