/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function, no-console */

import i18next from 'i18next';
import store from '@/services/store';
import mytako from '@/services/mytako';

const NS = 'mytako-frontend';

export const localeNames: { [locale: string]: string } = {
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    nl: 'Dutch',
};
const localeNamesKeys = Object.keys(localeNames);
export const locales = localeNamesKeys.filter((value) => (process.env.VUE_APP_I18N_LOCALES ?? localeNamesKeys).includes(value));

// mytako.api.i18n.getLanguages({ namespace: NS }).then((languages) => {
//     locales = languages;
//     console.warn('locales:', locales);
i18next
    //
    .use({
        type: 'backend',
        init: () => {},
        read: (language: string, namespace: string, callback: any) => {
            mytako.api.i18n.getTranslations({ language, namespace }).then(async (translations) => {
                callback(null, translations[language][namespace]);
            });
            // if method fails/returns an error, call this: callback(truthyValue, null);
        },
    })
    .init({
        lng: store.language.get() ?? process.env.VUE_APP_I18N_LOCALE ?? 'en',
        fallbackLng: 'en',
        ns: 'mytako-frontend',
        defaultNS: NS,
        fallbackNS: NS,
        preload: locales,
        parseMissingKeyHandler: (key: string) => (process.env.NODE_ENV === 'development' ? key : ''),
    });
// });

export default i18next;
